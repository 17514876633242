import GoogleMapComponent from '../components/GoogleMap/viewer/GoogleMap';
import GoogleMapController from '../components/GoogleMap/viewer/GoogleMap.controller';


const GoogleMap = {
  component: GoogleMapComponent,
  controller: GoogleMapController
};


export const components = {
  ['GoogleMap']: GoogleMap
};

